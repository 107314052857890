import React from 'react';
import styled from '@emotion/styled';
import { P1, P2, P3 } from 'components/Atom/Typo';

const Component = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0px;
  }

  ${P1} {
    line-height: 1;
    height: 27px;
    margin-right: 4px;
  }
  ${P2} {
    line-height: 1;
    height: 24px;
    color: ${(props) => props.theme.colors.grey600};
  }

  ${P3} {
    line-height: 1;
    height: 20px;
    color: ${(props) => props.theme.colors.primary};
    margin-left: 8px;
  }
`;

interface PriceLabelProps {
  className?: string;
  price: string;
}

const PriceLabel = ({ className, price }: PriceLabelProps) => {
  // function numberFormat(x: number) {
  //   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // }

  return (
    <Component className={className}>
      <P1>{price}</P1>
    </Component>
  );
};

export default PriceLabel;
