import React from 'react';
import styled from '@emotion/styled';
import { H1, H3, H4 } from 'components/Atom/Typo';
import PricingCard from '../Card/PricingCard';
import Button from 'components/Atom/Button';
import { css, keyframes } from '@emotion/react';
import { useState } from 'react';

const Component = styled.div`
  width: 100%;
  background-color: white;

  display: flex;
  justify-content: center;
`;

const MaxWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 0px 60px 160px 60px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;

  ${H4} {
    font-size: 14px;
    color: ${(props) => props.theme.colors.grey600};
    line-height: 1.57;
    margin-bottom: 24px;
  }

  ${H1} {
    font-size: 40px;
    line-height: 1.4;
  }
`;

const Body = styled.div`
  width: 100%;
`;

const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TabButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
  width: 100%;
`;

const PriceWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 650px;
`;

const Split = styled.div`
  width: 1px !important;
  height: 100%;
  background-color: ${(props) => props.theme.colors.grey200};
`;

const RadioButton = styled(Button)`
  width: 80px;
  height: 40px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.grey100};
  position: relative;
  padding: 4px;
`;

const moveRight = keyframes`
from {
  left: 4px;
}
to {
  left: 40px;
}
`;

const moveLeft = keyframes`
from {
  left: 40px;
}
to {
  left: 4px;
}
`;

const Circle = styled.div<{ isCloud: boolean }>`
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.11);
  background-color: ${(props) => props.theme.colors.primary};

  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  ${(props) => {
    if (props.isCloud) {
      return css`
        animation-name: ${moveLeft};
      `;
    }
    return css`
      animation-name: ${moveRight};
    `;
  }}
`;

const TabButton = styled(Button)<{ isActive: boolean }>`
  background-color: transparent;
  width: fit-content;
  ${(props) => {
    if (props.isActive) {
      return css`
        ${H3} {
          color: ${props.theme.colors.grey800};
        }
      `;
    }
    return css`
      ${H3} {
        color: ${props.theme.colors.grey600};
      }
    `;
  }}
`;

const Emptyblock = styled.div`
  width: 25%;
  height: 100%;
  background-color: white;
`;

const Pricing = () => {
  // const [isActiveID, setIsActiveID] = useState(0);
  return (
    <Component>
      <MaxWrapper>
        <Body>
          {/* <TabButtonWrapper>
            <TabButton
              isActive={isActiveID === 0}
              onClick={() => {
                setIsActiveID(0);
              }}
            >
              <H3>클라우드 형</H3>
            </TabButton>
            <RadioButton
              onClick={() => {
                setIsActiveID((prev) => {
                  if (prev === 0) {
                    return 1;
                  }
                  return 0;
                });
              }}
            >
              <Circle isCloud={isActiveID === 0} />
            </RadioButton>
            <TabButton
              isActive={isActiveID === 1}
              onClick={() => {
                setIsActiveID(1);
              }}
            >
              <H3>설치 형</H3>
            </TabButton>
          </TabButtonWrapper> */}
          <TabWrapper>
            <PriceWrapper>
              <PricingCard
                isOpen
                title="라이트용"
                price="별도 문의"
                featureList={[
                  '매월 1,000,000PV 이상 제공(커스텀 가능)',
                  '데이터 보관 6개월',
                  'VODA의 모든 기능 제공',
                  'VODA 데이터 기반 커스텀 보고서 (연 2회)',
                  '세부 항목별 상세 분석 결과 제공',
                  '기간별 분석 결과 제공',
                  '개선방안 도출',
                ]}
              />

              <PricingCard
                isOpen
                title="엔터프라이즈용"
                price="별도 문의"
                featureList={[
                  '매월 1,000,000PV 이상 제공(커스텀 가능)',
                  '데이터 보관 12개월',
                  'VODA의 모든 기능 제공',
                  'VODA 데이터 기반 커스텀 보고서 (연 4회)',
                  '세부 항목별 상세 분석 결과 제공',
                  '기간별 분석 결과 제공',
                  '개선방안 도출',
                  '검색엔진 통계 등 연계 분석',
                ]}
              />

              <PricingCard
                isOpen
                title="공공기관용"
                price="별도 문의"
                featureList={[
                  '매월 1,000,000PV 이상 제공(커스텀 가능)',
                  '온프리미스 협의 가능',
                  '데이터 보관 12개월',
                  'VODA의 모든 기능 제공',
                  'VODA 데이터 기반 커스텀 보고서 (연 4회)',
                  '세부 항목별 상세 분석 결과 제공',
                  '기간별 분석 결과 제공',
                  '개선방안 도출',
                  '검색엔진 통계 등 연계 분석',
                ]}
              />
            </PriceWrapper>
          </TabWrapper>
        </Body>
      </MaxWrapper>
    </Component>
  );
};

export default Pricing;
