import React from 'react';
import styled from '@emotion/styled';
import { H3, P1, P2, P3 } from 'components/Atom/Typo';
import PriceLabel from 'components/Molecule/Label/PriceLabel';
import LinkButton from 'components/Atom/Link';
import PriceCheckLabel from 'components/Molecule/Label/PriceCheckLabel';
import { css } from '@emotion/react';

const ContactButton = styled(LinkButton)<{ isOpen: boolean }>`
  width: 100%;
  height: 52px;
  border-radius: 8px;
  margin: 40px 0px;

  ${(props) => {
    if (props.isOpen) {
      return css`
        background-color: ${props.theme.colors.grey900};
        ${P3} {
          color: white;
        }
      `;
    }
    return css`
      background-color: ${props.theme.colors.grey400} !important;
      ${P3} {
        color: white;
      }
      /* cursor: not-allowed; */
    `;
  }}
`;

const PriceCheckLabel_0 = styled(PriceCheckLabel)``;

const Component = styled.div`
  flex: 1;
  height: 100%;
  max-width: 350px;
  padding: 40px 24px;
  border: solid 1px ${(props) => props.theme.colors.grey200};
  border-radius: 16px;
  background-color: white;

  display: flex;
  flex-direction: column;

  ${H3} {
    line-height: 1.5;
    margin-bottom: 24px;
  }

  margin-right: 24px;
  &:last-child {
    margin-right: 0px;
  }

  :hover {
    background-color: ${(props) => props.theme.colors.grey900};

    ${H3},${P2},${P1} {
      color: white;
    }

    ${ContactButton} {
      background-color: ${(props) => props.theme.colors.primary};
    }

    ${PriceCheckLabel_0} {
      path {
        fill: white;
      }
      ${P3} {
        color: white;
      }
    }
  }
`;

const SeparateText = styled.div`
  height: 35px;
`;

interface PricingCardProps {
  title: string;
  isSeparate?: boolean;
  price: string;
  featureList: string[];
  isOpen: boolean;
}

const PricingCard = ({
  title,
  featureList,
  price,
  isOpen,
  isSeparate,
}: PricingCardProps) => {
  return (
    <Component>
      <H3>{title}</H3>
      {isSeparate ? (
        <SeparateText>
          <P1>별도 문의</P1>
        </SeparateText>
      ) : (
        <PriceLabel price={price} />
      )}

      <ContactButton to="/contact/form" isOpen={isOpen}>
        <P3>{isOpen ? '문의하기' : '오픈예정'}</P3>
      </ContactButton>

      {featureList.map((element) => {
        return <PriceCheckLabel_0 key={element} title={element} />;
      })}
    </Component>
  );
};

export default PricingCard;
